<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<!-- Mat loader starts -->
<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container" style="font-family: 'Montserrat-regular', Arial, Helvetica, sans-serif;">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->

<div class="search_results_view_section">
  <div class="search-results-section">
    <div class="user-upld-document">
      <a href="javascript:void(0);" routerLink="/"
        ><img src="../../assets/images/svg/back-arrow.svg" alt="back arrow"
      /></a>
      <p style="font-family: 'Montserrat-regular', Arial, Helvetica, sans-serif;">
        Uploaded document: <span>{{ documentData.fileName }}</span>
      </p>
    </div>
    <div class="results-category">
      <ul>
        <li>
          <a class="true"
            >True:
            <span>{{ documentData.trueAssertionsCount }}</span> assertions</a
          >
        </li>
        <li>
          <a class="partially-true"
            >Partially True:
            <span>{{ documentData.partiallyTrueAssertionsCount }}</span>
            assertions</a
          >
        </li>
        <li>
          <a class="false"
            >False:
            <span>{{ documentData.falseAssertionsCount }}</span> assertions</a
          >
        </li>
      </ul>
      <div class="correction-btn" [ngClass]="{'highlight-tutorial': highlightedElement === 8}">
        <a
          >Corrections: <span>{{
            documentData.assertionsWithErrorsCount
              ? documentData.assertionsWithErrorsCount
              : 0
          }}</span></a
        >
      </div>
    </div>

    <!-- (click)="addClickListenersToGuidElements()" -->
    <div #assertionsContainer class="assertions_data" [ngClass]="{'highlight-tutorial': highlightedElement === 3}" (clickOutside)="closeCorrection()">
      <div
        class="assertion-content-sec"
        [innerHTML]="htmlContent"
        (mouseover)="onMouseOver($event)"
      ></div>
    
      <div *ngIf="editingSentence" class="edit-box">
        <p><b>Edit assertion below:</b></p>
        <textarea [(ngModel)]="editedText"></textarea>
        <button class="save-button" (click)="save()">Save</button>
        <button class="save-run-button" (click)="saveAndReRun()">Save & Re-run</button>
        <button class="cancel-button" (click)="cancelEdit()">Cancel</button>
      </div>
    </div>
    

    <div class="assertions-editor-section">
      <!-- <app-ngx-editor [placeholder]="'Enter text here...'" [spellcheck]="true" [(ngModel)]="edtablehtmlContent"></app-ngx-editor> -->
    </div>
  </div>

  <div class="results-score-section">
    <div class="download-assertion" (click)="downloadDocument()">
      <img src="../../assets/images/svg/download-icon.svg" alt="download" />
    </div>
    <!---- Overall Score ---->
    <div class="overall_scroll_sec" [ngClass]="{'highlight-tutorial': highlightedElement === 4}" *ngIf="documentScore !== 0">
      <div class="truth-meter-section">
        <h3 style="font-family: 'Montserrat-regular', Arial, Helvetica, sans-serif;">Overall Score</h3>
        <!-- <span  class="label_text" *ngIf="labelText && !ranking && env === 'dev-portal'">{{labelText}}</span> -->
        <ng-container>
          <div
            class="trust-guage-section"
            [attr.data-truth-meter-score]="truthMeter"
          >
            <rg-gauge-chart
              [canvasWidth]="canvasWidth"
              [needleValue]="needleValue"
              [centralLabel]="centralLabel"
              [options]="options"
            >
            </rg-gauge-chart>
            <h4 style="font-family: 'Montserrat-regular', Arial, Helvetica, sans-serif; font-style: normal;">{{ graphType }}</h4>
          </div>
        </ng-container>
      </div>
    </div>

    <!---- Counter ---->
    <div class="counter-section" [ngClass]="{'highlight-tutorial': highlightedElement === 5}">
      <h3>Counter</h3>
      <div class="counter-block">
        <ul>
          <li *ngIf="documentData.totalSentences">
            Total Sentences:
            {{ documentData.totalSentences ? documentData.totalSentences : 0 }}
          </li>
          <li *ngIf="documentData.characterCounter">
            Characters Count:
            {{
              documentData.characterCounter ? documentData.characterCounter : 0
            }}
          </li>
          <li *ngIf="documentData.assertionsWithErrorsCount">
            Assertions with Errors:
            {{
              documentData.assertionsWithErrorsCount
                ? documentData.assertionsWithErrorsCount
                : 0
            }}
          </li>
          <li *ngIf="documentData.logicalFallaciesCount">
            Logical Fallacies Count:
            {{
              documentData.logicalFallaciesCount
                ? documentData.logicalFallaciesCount
                : 0
            }}
          </li>
        </ul>
      </div>
    </div>

    <!---- View Assertions ---->
    <div class="feature-links">
      <ul>
        <!-- <li><a (click)="openModal('assertion')">View Assertions</a></li> -->
        <li>
          <a 
            (click)="openModal('Syllogims')" 
            [ngClass]="{'highlight-tutorial': highlightedElement === 6}" 
            class="syllogisms-link"
          >
            <!-- Display only "View Syllogisms" if in tutorial -->
            <span *ngIf="highlightedElement === 6" class="syllogisms-text">
              View Syllogisms
            </span>
        
            <!-- Display percentage and loader when not in tutorial -->
            <span *ngIf="highlightedElement !== 6" class="syllogisms-text">
              View Syllogisms {{ percentageProcessed }}%
            </span>
        
            <div class="syllogisms-loader">
              <div 
                class="syllogisms-loader-fill" 
                [style.width.%]="percentageProcessed"
              ></div>
            </div>
          </a>
        </li>
        <li><a (click)="openModal('Fallacies')" [ngClass]="{'highlight-tutorial': highlightedElement === 7}">View Logical Fallacies</a></li>
      </ul>
    </div>
  </div>
</div>
